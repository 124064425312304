import { $body } from '../utils/globals';

$('.js-nav-trigger').on('click', function (ev){
	 ev.preventDefault();

	 const bodyClass = $(this).is('.js-nav-trigger--alt') ? 'has-active-nav-alt' : 'has-active-nav';

	 $(this).toggleClass('is-active');

	 $body.toggleClass(bodyClass);
});

$('.menu-item-has-children > span').on('click', function() {
	$(this).parent().toggleClass('is-active');
});
