import Typed from 'typed.js';

$('.hero').each((i, container) => {
	const $container = $(container);

	const image = $container.find('.hero__image');

	const typedElem = $container.find('[data-typed]')[0];

	const strings = JSON.parse(typedElem.dataset.typed.replace(/\'/g, '"'));


	const options = {
		strings,
		loop: true,
		typeSpeed: 100,
		backSpeed: 100,
		backDelay: 1000,
		smartBackspace: false,
    preStringTyped(pos, self) {
    	image.eq(pos).addClass('is-active').siblings().removeClass('is-active');
    },
  };

  new Typed(typedElem, options);
});
