/*
 * Polyfills
 */
import './polyfills/object-fit';

/*
 * Modules
 */

import './modules/mobile-nav'
import './modules/hero'
import './modules/init-mfp'
import './modules/snap'
