import { $win } from '../utils/globals';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const $container = $('.section-solutions .section__body');
const $panels = $('.section-solutions .section__group');
const step = 100 / $panels.length;

if ($container.length) {
	ScrollTrigger.matchMedia({
		'(min-width: 1024px)': function(){
		  ScrollTrigger.create({
		    trigger: $container,
		    start: "top top",
        end: `+=${$panels.length * 5 + 1}0%`,
		    pin: $container,
		    // markers: true,
		    onUpdate: self => {
		    	const index = parseInt(self.progress * 100  / step);

		    	$panels.eq(index).addClass('is-active').next().removeClass('is-active');
		    }
		  });
		}
	});
}
